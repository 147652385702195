import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import KurzUebersicht from './KurzUebersicht';
import SwipeableTextMobileStepper from './ImageSlider';

function Startseite() {
  // const [onlineStat,setOnlineStat] = useState(false);

  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matches = useMediaQuery('(min-width:451px)');


  return (
    <>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', p: { xs: 2, sm: 4 }, backgroundColor: 'rgba(255,255,255,1)' }}>
        <Box sx={{ flex: 3 }}>
          {/* <Typography variant='h5' >Hausarzt- und Familienpraxis</Typography> */}
          {/* <Typography variant='h5' sx={{fontWeight:'bold',mb:2,textDecoration: 'underline',textDecorationColor: 'rgb(234, 191, 64)'}}>Dr.&nbsp;med.&nbsp;S.&nbsp;Stähle & Dr.&nbsp;med.&nbsp;S.&nbsp;Winkler</Typography> */}
          <Typography variant='h5' sx={{fontWeight:'bold',mb:2,
          // textDecoration: 'underline',
          textDecorationColor: 'rgb(234, 191, 64)'}}>Fachärztinnen für Allgemeinmedizin, Naturheilverfahren,
          {matches?" ":<br/>}
          Akupunktur</Typography>
          
          {/* <Box sx={{height:'10px',backgroundColor:'rgb(234, 191, 64)'}}></Box> */}
          <Box sx={{display:{xs:'block',sm:'none',}}}>

            <SwipeableTextMobileStepper></SwipeableTextMobileStepper>
          </Box>
          <Box sx={{display:{xs:'none',sm:'flex'}, p: { md: 3, xs: 0 }, pl: 0,  flexDirection: { sm: 'row', xs: 'column' } }}>
            <Box sx={{ flex: 1, display: 'flex', flexWrap: 'nowrap', mb: { sm: 0, xs: 1 } }}>

              <Box
                component="img"
                sx={{
                  // maxInlineSize: '100%',
                  // blockSize: 'auto',
                  width: '100%',
                  height: '100%',
                  minWidth: 0,
                  mr: { sm: 1, xs: 0 }
                  // height: 233,
                  // display: 'inline-table',
                  // overflow: 'hidden',
                  // flex:1
                  // width: '100%',

                  // maxHeight: { xs: 233, md: 167 },
                  //  maxWidth: { xs: 350, md: 400 },
                }}
                alt="Rezeption der Arztpraxis."
                src="/3008_19.webp"
              />
            </Box>
            <Box sx={{ flex: 1, display: 'flex', flexWrap: 'nowrap', maxWidth: { sm: "50%", xs: "100%" } }}>

              <Box
                component="img"
                sx={{
                  // height: 233,
                  // display: 'inline-table',
                  // overflow: 'hidden',
                  // flex:1,
                  width: '100%',
                  height: '100%',
                  minWidth: 0,
                  ml: { sm: 1, xs: 0 },
                  // maxHeight: { xs: 233, md: 167 },
                  //  maxWidth: { xs: 350, md: 400 },
                }}
                alt="Innenansicht der Arztpraxis."
                src="/3008_20.webp"
              />
            </Box>
          </Box>
          {/* <Box sx={{height:'10px',backgroundColor:'rgb(234, 191, 64)'}}></Box> */}
          <Typography sx={{fontWeight:'bold',fontSize:20,mt:{xs:-4,sm:2, },pl:2,pr:2}}>
          Herzlich willkommen in unserer Hausarztpraxis!
          </Typography>
          <Typography sx={{ mt:2,textAlign:'justify',pl:2,pr:2}}>
          <span style={{fontWeight:"bold"}}>Liebe Patientinnen und Patienten,</span> <br></br>
<br/>
unsere Gemeinschaftspraxis bietet Ihnen langjährige Erfahrung und eine Behandlung nach dem aktuellen Stand der Medizin für die ganze Familie. Wir beraten und behandeln Sie bei allen akuten und chronischen Erkrankungen. 
<br/>
<br/>
Bei uns stehen Sie als Patient mit Ihren Wünschen und Fragen im Mittelpunkt.
<br/>
<br/>
Zusätzlich hilft ein breites Spektrum an naturheilkundlichen Verfahren Ihre Gesundheit zu erhalten.
<br/>
<br/>

Wir freuen uns, Sie in unseren neuen, ebenerdigen und barrierefreien Räumen im Kelterweg 7 in Heilbronn-Horkheim begrüßen zu dürfen!
            
            


          </Typography>


        </Box>
        <KurzUebersicht></KurzUebersicht>
      </Box>
      <Box sx={{ p: 1, backgroundColor: 'rgba(255,255,255,1)' }}>
        <Box sx={{ margin: 'auto', display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ flex: 1 }}></Box>
          <Box
            component="img"
            sx={{
              height: { md: '300px', xs: 'inherit' },
              // width: '100%',
              margin: 'auto'
              // maxHeight: { xs: 233, md: 167 },
              //  maxWidth: { xs: 350, md: 400 },
            }}
            alt="Leitmotiv"
            src="/startseite.png"
          />
          <Box sx={{ flex: 1 }}></Box>
        </Box>
        {/* <Box>

          Dr. med. Sabine Stähle
          Geb. 1966 in Heilbronn

          Studium in Heidelberg, mehrere Auslandsaufenthalte.
          Klinische Ausbildung im Krankenhaus Heilbronn und in Rehakliniken.
          Seit vielen Jahren Allgemeinärztin in Heilbronn.

          Dr. med. Sabine Winkler
          Geb. 1966 in Buchen

          Studium in Heidelberg und Mannheim, mehrere Auslandsaufenthalte.
          Klinische Ausbildung im Krankenhaus „Am Plattenwald“ in Bad Friedrichshall.
          Seit 2003 hausärztlich in der Region Heilbronn tätig.
        </Box> */}
        <Box sx={{ minHeight: '50px' }}>

        </Box>
      </Box>
    </>



  );
}

export default Startseite;









