import React, { useEffect, useState } from "react";
// import logo from './logo.svg';
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Close, Router } from "@mui/icons-material";
import { Box, Icon, Typography, useTheme } from "@mui/material";
import LogoSVG from "../logoSVG";
import Menu from "@mui/icons-material/Menu";

import useMediaQuery from "@mui/material/useMediaQuery";
import { routesOfApp, routingOfApp } from "../routes/AppRoutes";
import { useNavigate } from "react-router-dom";

function Headline() {
  // const [onlineStat,setOnlineStat] = useState(false);
  const [svgSize, setSvgSize] = useState(33 * 2);
  const [svgOffset, setSvgOffset] = useState(6 * 2);
  const [grow, setGrow] = useState(true);

  const getGrow = () => {
    return grow;
  };
  const navigate = useNavigate();
  const changeSVG = () => {
    // let tmpSize=svgSize;
    // let tmpGrow = getGrow();
    // console.log("grow",grow)
    // if(svgOffset<=6){
    //   console.log("grow","small")
    //   setGrow(true)
    // }
    // else if(svgOffset>=6*1.2){
    //   console.log("grow","big")
    //   setGrow(false)
    // }
    // setSvgOffset(p=>grow?p*1.01:0.99)
    // setSvgSize(p=>grow?p*1.01:0.99)
  };

  useEffect(() => {
    const id = setInterval(() => changeSVG(), 100);

    return () => clearInterval(id);
  }, []);

  const Menue = routingOfApp[0] ? routingOfApp[0].children : [];

  // const Menue = [
  //   {
  //     name: "Startseite",
  //     navLink: ""
  //   },
  //   {
  //     name: "Leistungsspektrum",
  //     navLink: ""
  //   },
  //   {
  //     name: "Naturheilkunde & Akupunktur",
  //     navLink: ""
  //   },
  //   {
  //     name: "Über uns",
  //     navLink: ""
  //   },
  //   {
  //     name: "Sprechzeiten & Anfahrt",
  //     navLink: ""
  //   },
  //   // {
  //   //   name: "Datenschutz",
  //   //   navLink: ""
  //   // },
  //   // {
  //   //   name: "Impressum",
  //   //   navLink: ""
  //   // },
  // ]

  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    setOpenMenu(true);
  }, [matchesLG]);
  const matchesLG_Down = useMediaQuery(theme.breakpoints.up("xs"));
  useEffect(() => {
    if (!matchesLG) {
      setOpenMenu(false);
    }
  }, [matchesLG_Down, matchesLG]);

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <Box
        sx={{
          pt: { sm: 3, xs: 0 },
          pb: { sm: 3, xs: 0 },
          // height: {sm:150,xs:180},
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
          backgroundColor: "rgb(234, 191, 64)",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Box
            id="TestBox"
            sx={{
              width: 100,
              display: "flex",
              flexDirection: "column",
              marginLeft: 2,
              marginRight: 2,
            }}
          >
            <Box sx={{ flex: 1 }}></Box>
            {/* <Icon sx={{  flex:1, margin:'auto',textAlign:'center'}}> */}
            <Box sx={{ paddingLeft: svgOffset == 6 ? 6 * 0.1 : 0 }}>
              <LogoSVG widthP={svgSize} offset={svgOffset} />
            </Box>
            {/* </Icon> */}
            <Box sx={{ flex: 1 }}></Box>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", color: "#505050" }}
          >
            <Box sx={{ flex: 1 }}></Box>
            <Typography variant="h4" sx={{ fontSize: "1.3rem" }}>
              Hausarzt- und Familienpraxis
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
            >
              <span
                style={
                  {
                    // fontSize:"1.75rem"
                  }
                }
              >
                Dr. med.
              </span>{" "}
              S. Stähle
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "1.5rem", sm: "2rem" },
              }}
            >
              <span
                style={
                  {
                    // fontSize:"1.75rem"
                  }
                }
              >
                Dr. med.
              </span>{" "}
              S. Winkler
            </Typography>
            <Box sx={{ flex: 1 }}></Box>
          </Box>
        </Box>
        {/* <Box sx={{ flex: 1, display: { xs: 'none', lg: 'flex' } }}>

        </Box> */}
        <Box
          sx={{
            flex: 1,
            display: { xs: "none", lg: "flex" },
            flexDirection: "row",
            color: "#505050",
            maxWidth: 300,
          }}
        >
          <Box sx={{ flex: 1 }}></Box>
          <Box
            sx={{
              display: { xs: "none", lg: "flex", flexDirection: "column" },
              mr: 5,
            }}
          >
            <Box sx={{ flex: 1 }}></Box>
            <Typography>Kelterweg 7</Typography>
            <Typography>74081 Heilbronn-Horkheim</Typography>
            <Typography>Telefon 07131-574691, Fax 250916</Typography>
            <Typography>www.praxis-staehle-winkler.de</Typography>
            <Box sx={{ flex: 1 }}></Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            mr: 2,
            flexDirection: { xs: "row", sm: "column" },
            backgroundColor: "rgb(234, 191, 64)",
          }}
        >
          <Box
            sx={{
              flex: { xs: "inherit", sm: 1 },
              ml: { xs: 2, sm: "inherit" },
            }}
          ></Box>
          {!openMenu && (
            <IconButton
              aria-label="Menü öffnen"
              sx={{ color: "#505050" }}
              onClick={() => {
                setOpenMenu((p) => !p);
              }}
            >
              <Menu></Menu>
            </IconButton>
          )}
          {openMenu && (
            <IconButton
              aria-label="Menü schließen"
              sx={{ color: "#505050" }}
              onClick={() => {
                setOpenMenu((p) => !p);
              }}
            >
              <Close></Close>
            </IconButton>
          )}
          <Box sx={{ flex: { xs: 1, sm: "inherit" } }}></Box>
        </Box>
      </Box>
      <Box
        sx={{
          transition: "2s",
          height: { xs: 150, md: 60 },
          display: openMenu ? "flex" : "none",
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "white",
          pl: 4,
          pr: 4,
          justifyContent: "center",
          gap:{xs: "inherit", md:5}
        }}
      >
        {Menue &&
          Menue.map((el, k) => (
            <Box
              id="Menu"
              onClick={() => {
                navigate(String(el.path));
                if (!matchesLG) {
                  setOpenMenu(false);
                }
              }}
              sx={{
                ":hover": {
                  color: "black",
                  fontWeight: "bold",
                  cursor: "pointer",
                },
                fontWeight:
                  window.location.pathname == el.path ? "bold" : "inherit",
                lineClamp: 2,
                width: {
                  xs: "100%",
                  md: el.maxWidthOfRoute ? el.maxWidthOfRoute : "inherit",
                },
                maxWidth: {
                  xs: "100%",
                  md: el.maxWidthOfRoute ? el.maxWidthOfRoute : "inherit",
                },
                color: "#505050",
                flex: 1,
                p: 0.1,
                textAlign: { xs: "left", md: "center" },
                textTransform: "uppercase",
                margin: { xs: "auto", md: "auto" },
                display: { xs: "block", md: "block" },
                borderBottom: {
                  xs: k !== Menue.length - 1 ? "2px solid #eabf40" : "inherit",
                  md: "inherit",
                },
              }}
            >
              {el.name}
            </Box>
          ))}

        <Box></Box>
      </Box>
      <Box sx={{ height: 5, backgroundColor: "rgb(234, 191, 64)" }}></Box>
    </>
  );
}

export default Headline;
