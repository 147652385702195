import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
// import './Header.css';




function LogoSVG(props) {
    let width = props.widthP;
    let offset = props.offset;
    if(!width){
        width=33
    }
    if(!offset){
        offset=6
    }
    
    let cross_width = width*0.1724;
    let cross_height = 0.586;
    let points = String(offset+width/2-cross_width/2)+","+ //x
                 String(offset+width/2-cross_width/2)+" "+ //y 

                 String(offset+width/2-cross_width/2)+","+ //x  
                 String(offset+width/2-width*cross_height/2)+" "+ //y

                 String(offset+width/2+cross_width/2)+","+ //x
                 String(offset+width/2-width*cross_height/2)+" "+ //y 

                 String(offset+width/2+cross_width/2)+","+
                 String(offset+width/2-cross_width/2)+" "+  

                 String(offset+width/2+width*cross_height/2)+","+
                 String(offset+width/2-cross_width/2)+" "+ 

                 String(offset+width/2+width*cross_height/2)+","+
                 String(offset+width/2+cross_width/2)+" "+

                 String(offset+width/2+cross_width/2)+","+
                 String(offset+width/2+cross_width/2)+" "+   

                 String(offset+width/2+cross_width/2)+","+
                 String(offset+width/2+width*cross_height/2)+" "+   

                 String(offset+width/2-cross_width/2)+","+
                 String(offset+width/2+width*cross_height/2)+" "+   

                 String(offset+width/2-cross_width/2)+","+
                 String(offset+width/2+cross_width/2)+" "+   

                 String(offset+width/2-width*cross_height/2)+","+
                 String(offset+width/2+cross_width/2)+" "+   

                 String(offset+width/2-width*cross_height/2)+","+
                 String(offset+width/2-cross_width/2)+" "+   

                 String(offset+width/2-cross_width/2)+","+
                 String(offset+width/2-cross_width/2)+" ";   

    return(
        <>
        
        <svg id="svgElement" width={width+2*offset} height={width+2*offset}>
            <circle cx={width/2+offset} cy={width/2+offset} r={width/2+width*0.1379}  strokeWidth="3" fill="white" opacity="0.7"></circle>
            <circle cx={width/2+offset} cy={width/2+offset} r={width/2}  strokeWidth="3" fill="white"></circle>
            <polygon points={points} fill={props.color?props.color:"#e8d969"}/>
        </svg>
        </>
    )
}


export default LogoSVG