import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box } from '@mui/material';
import Headline from '../Headline/Headline';
import Startseite from '../Sites/Startseite';
import Footer from '../Headline/Footer';
import AppRouter from '../routes/AppRouter';
import { BrowserRouter } from 'react-router-dom';

function App() {
  // const [onlineStat,setOnlineStat] = useState(false);




  return (
    <>
      <BrowserRouter>
        <Box sx={{
          // height:'100vh',
          width: '100vw', minHeight: '100vh', backgroundColor: '#dddddd', display: 'flex', flexDirection: 'column'
        }}>
          <Box sx={{display:'flex',flexDirection:'column', flex: 1, ml: { md: 'auto', xs: 0 }, mr: { md: 'auto', xs: 0 }, width: { lg: '70vw', xs: '100vw' }, boxShadow: '0px 0px 16px 2px rgba(0,0,0,0.96)' }}>

            <Headline></Headline>
            <Box sx={{ display:'flex',flexDirection:'column', flex:1 }}>

              <AppRouter></AppRouter>
              <Box sx={{flex:1, display:'flex',flexDirection:'column'}}>
                <Box sx={{flex:1, backgroundColor:'white'}}></Box>
                <Footer></Footer>
              </Box>
            </Box>
            {/* Todo Footer */}
          </Box>
        </Box>
      </BrowserRouter>
    </>



  );
}

export default App;









