import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label: 'Innenansicht der Arztpraxis.',
        imgPath:
            '/3008_20.webp',
    },
    {
        label: 'Rezeption der Arztpraxis.',
        imgPath:
            '/3008_19.webp',
    },
    // {
    //     label: 'Bali, Indonesia',
    //     imgPath:
    //         'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    // },
    // {
    //     label: 'Goč, Serbia',
    //     imgPath:
    //         'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    // },
];

function SwipeableTextMobileStepper() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{  flexGrow: 1 }}>
            {/* <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
                { <Typography>{images[activeStep].label}</Typography> }
            </Paper> */}
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={5000}
            >
                {images.map((step, index) => (
                    <>
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <Box
                                    component="img"
                                    sx={{
                                        // height: 255,
                                        display: 'block',
                                        // maxWidth: 400,
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                    src={step.imgPath}
                                 alt={step.label}
                                />
                            ) : null}
                        </div>
                    </>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper sx={{ zIndex: 20, opacity: 0.8,position:"relative", top:-40, backgroundColor:'inherit', color:'black',
                "& .MuiMobileStepper-dot": {
                    backgroundColor: theme.palette.action.disabled
                },
                "& .MuiMobileStepper-dotActive ": {
            backgroundColor: 'rgb(234, 191, 64)'
        },
        ".MuiMobileStepper-root":{
            backgroundColor:'white'
        }    
        
        }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                    sx={{backgroundColor:'inherit', color:'rgb(234, 191, 64)'}}
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                        aria-label="Right Image"
                        >
                        
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                                )}
                    </Button>
                }
                backButton={
                    <Button 
                    sx={{backgroundColor:'inherit', color:'rgb(234, 191, 64)'}}
                        aria-label="Right Image"
                    size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                                )}
                        
                    </Button>
                }
            />
        </Box>
    );
}

export default SwipeableTextMobileStepper;