import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Add, Router } from '@mui/icons-material';
import { Box, Divider, Icon, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import KurzUebersicht from './KurzUebersicht';
import LogoSVG from '../logoSVG';

function Naturheilkunde() {
  // const [onlineStat,setOnlineStat] = useState(false);


  const options = [

    "Akupunktur – Zur Schmerzbehandlung, bei Tinnitus, Allergien, zur Nikotinentwöhnung, zur Geburtsvorbereitung und bei vielen anderen Erkrankungen",
    "Behandlung mit pflanzenheilkundlichen, homöopathischen und anthroposophischen Medikamenten",
    "Mikrobiologische Darmdiagnostik und Therapie",
    "Aufbaukuren",
    "Eigenblutbehandlungen",
    "u.v.m."
  ]

  return (
    <>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', p: 4, backgroundColor: 'rgba(255,255,255,1)' }}>
        <Box sx={{ flex: 3 }}>
          <Typography variant='h5' sx={{fontWeight:'bold',}}>Naturheilkunde und Akupunktur</Typography>
          {/* <Box sx={{height:'10px',backgroundColor:'rgb(234, 191, 64)'}}></Box> */}
          <Box sx={{ p: 3, pl: 0, display: 'flex', flexDirection: 'column' }}>
            {/* <Box>Zusätzlich hilft ein breites Spektrum an naturheilkundlichen Verfahren ihre Gesundheit zu erhalten:
            </Box> */}
            <List sx={{ bgcolor: 'background.paper' }}>
      {
        options.map((el,k)=><>
        <ListItem key={el}  sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ mt: -0.11, mr: 1,ml: 0}}>

          <Icon>
            <LogoSVG color={"#4fa74f"} widthP={25} offset={0.1}></LogoSVG>
          </Icon >
        </Box>
        <ListItemText primary={el} sx={{flex:1}}/>
      </ListItem>
      
      {(!(k==options.length-1))&&<Divider />}
        </>)
      }
      </List>
      
    
    
    <Box>Sollten Sie eine Behandlungsalternative vermissen, sprechen Sie uns bitte an!
            </Box>

          </Box>


        </Box>
        <KurzUebersicht></KurzUebersicht>
      </Box>
    </>



  );
}

export default Naturheilkunde;









