import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import KurzUebersicht from './KurzUebersicht';
import Notdienst from './Notdienst';

function SprechzeitenAnfahrt() {
  // const [onlineStat,setOnlineStat] = useState(false);




  return (
    <>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', p: 4, backgroundColor: 'rgba(255,255,255,1)' }}>
        <Box sx={{ flex: 3, p: 2 }}>
          <Typography sx={{ mb: { xs: 3, md: 'inherit' },fontWeight:'bold' }}  variant='h5'>Sprechzeiten unserer Gemeinschaftspraxis</Typography>
          <Typography sx={{ mt: 3, fontWeight: 'bold' }}>

            Vormittags:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{}}>
              <Typography>Mo - Do</Typography>
              <Typography>Fr</Typography>
            </Box>
            <Box sx={{ ml: 3, flex: 1 }}>
              <Typography>8-12 Uhr</Typography>
              <Typography>8-13 Uhr</Typography>
            </Box>

          </Box>
          <Typography sx={{ mt: 3, fontWeight: 'bold' }}>

            Nachmittags:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{}}>
              <Typography>Mo & Mi</Typography>
              <Typography>Do</Typography>
            </Box>
            <Box sx={{ ml: 3, flex: 1 }}>
              <Typography>15-17 Uhr</Typography>
              <Typography>16-18 Uhr</Typography>
            </Box>
            <Box sx={{ flex: 1 }}>

            </Box>


          </Box>
            <Typography sx={{mt:2}}>und nach Vereinbarung.</Typography>
            <Typography sx={{mt:2}}>Wir bitten um <b>telefonische Terminabsprache</b>.</Typography>



          <Typography variant='h5' sx={{ mt: 3 }}>So erreichen Sie uns</Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ mt: 3,  display: 'flex', flexWrap: 'nowrap' }}>

              <Box
                component="img"
                sx={{
                  // maxInlineSize: '100%',
                  // blockSize: 'auto',
                  width: '100%',
                  maxWidth: 400,
                  height: '100%',
                  minWidth: 0,
                  mr: 1
                  // height: 233,
                  // display: 'inline-table',
                  // overflow: 'hidden',
                  // flex:1
                  // width: '100%',

                  // maxHeight: { xs: 233, md: 167 },
                  //  maxWidth: { xs: 350, md: 400 },
                }}
                alt="Rezeption der Arztpraxis."
                src="/anfahrt_neu.webp"
              />
            </Box>
            <Box sx={{mt: 3, display:'flex', flexDirection:'column', ml:{xs:0,md:4}}}>
              <Box sx={{flex:1}}></Box>
              <Box>

              <Typography>Kelterweg 7</Typography>
              <Typography>74081 Heilbronn-Horkheim</Typography>
              <Typography>Telefon 07131-574691</Typography>
              <Typography>Fax 07131-250916</Typography>
              </Box>
              <Box sx={{flex:4}}></Box>
            </Box>
          </Box>
          <Box sx={{mt:2}}>

                <Notdienst hideOnSmall={false} hideOnBig={true}></Notdienst>
          </Box>
        </Box>
        <Notdienst hideOnSmall={true} hideOnBig={false}></Notdienst>
      </Box>

      <Box sx={{ minHeight: '50px', backgroundColor: 'rgba(255,255,255,1)' }}>

      </Box>
    </>



  );
}

export default SprechzeitenAnfahrt;









