import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandLess, ExpandMore, Router } from '@mui/icons-material';
import { Box, Collapse, Divider, Icon, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import KurzUebersicht from './KurzUebersicht';
import LogoSVG from '../logoSVG';

const CustomizedListItem = ({ doc, last }: { doc: { name: string, description: string },last:boolean }) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <div>
      <ListItem key={doc.name} onClick={handleClick} sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ mt: -0.11, mr: 1,ml: 0}}>

          <Icon>
            <LogoSVG widthP={25} offset={0.1}></LogoSVG>
          </Icon >
        </Box>
        <ListItemText primary={doc.name} sx={{flex:1, whiteSpace: 'pre-line'}}/>
        {/* {open ? <ExpandLess /> : <ExpandMore />} */}
      </ListItem>
      {/* <Collapse
        key={doc.name}
        in={open}
        timeout='auto'
        unmountOnExit
      >
        <List component='li' disablePadding key={""}>


          <ListItem button key={""}>
            <ListItemIcon>
              {/* <InsertDriveFileTwoToneIcon /> }
            </ListItemIcon>
            <ListItemText key={""} primary={doc.description} />
           </ListItem>

        </List>
      </Collapse> */}
      {(!last)&&<Divider />}
    </div>
  )
}

function Leistungsspektrum() {
  // const [onlineStat,setOnlineStat] = useState(false);



  const LeistungsListe = [
    {
      name: "EKG",
      description: ""
    },
    {
      name: "Lungenfunktionsuntersuchungen",
      description: ""
    },
    {
      name: "Ultraschalluntersuchungen (Sonographie)",
      description: ""
    },
    {
      name: "Vorsorgeuntersuchungen:\nCheck-up, Hautkrebsvorsorge, Männerkrebsvorsorge, Kinder- und Jugenduntersuchungen",
      description: ""
    },
    {
      name: "Betreuungsprogramme für chronisch Kranke",
      description: ""
    },
    {
      name: "Möglichkeit zur Teilnahme an den Hausarztprogrammen der Krankenkassen",
      description: ""
    },
    {
      name: "Psychosomatische Medizin",
      description: ""
    },
    {
      name: "Untersuchungen vor Operationen",
      description: ""
    },
    {
      name: "Wundversorgung und Behandlung nach OP´s",
      description: ""
    },
    {
      name: "Hausbesuche",
      description: ""
    },
    {
      name: "Reisemedizin",
      description: ""
    },
    {
      name: "Sporttauglichkeitsuntersuchungen",
      description: ""
    },
    {
      name: "Führerscheinuntersuchungen",
      description: ""
    },
    {
      name: "Erstellung von Reha- und Kuranträgen",
      description: ""
    },
    {
      name: "Reizstrombehandlungen, TENS",
      description: ""
    },
  ]
















  return (
    <>

      <Box sx={{ flex: 1, display: 'flex', flexDirection: {md:'row',xs:'column'}, p:4,  backgroundColor: 'rgba(255,255,255,1)' }}>
        <Box sx={{ flex: 3 ,p: 3}}>
          <Typography variant='h5' width={'100%'} sx={{fontWeight:'bold',}}>Leistungsspektrum</Typography>
          {/* <Box sx={{height:'10px',backgroundColor:'rgb(234, 191, 64)'}}></Box> */}
          <Box sx={{ p: 3, pl: 0, display: 'flex', flexDirection: 'column' }}>
            {/* <Box> */}
              {/* <Typography sx={{textAlign:'justify'}}>

              Liebe Patientinnen und Patienten, <br></br>

              unsere Gemeinschaftspraxis bietet Ihnen langjährige Erfahrung und eine Behandlung nach dem aktuellen Stand der Medizin für die ganze Familie. Wir beraten und behandeln Sie bei allen akuten und chronischen Erkrankungen. Bei uns stehen Sie als Patient mit Ihren Wünschen und Fragen im Mittelpunkt.
              </Typography> */}
            {/* </Box> */}
            {
              LeistungsListe.map((doc,k) =>
                <CustomizedListItem last={k==LeistungsListe.length-1?true:false} doc={doc}></CustomizedListItem>
              )
            }
          </Box>


        </Box>
        <KurzUebersicht></KurzUebersicht>
      </Box>



    </>



  );
}

export default Leistungsspektrum;









