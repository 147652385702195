import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

function Notdienst({hideOnSmall=true,hideOnBig=false}:{hideOnSmall:boolean,hideOnBig:boolean}) {
  // const [onlineStat,setOnlineStat] = useState(false);




  return (
    <>
      <Box sx={{ flex: 1, flexDirection: 'column', display: { xs: hideOnSmall?'none':"flex", md: hideOnBig?'none':'flex' }, color: '#505050' }}>
        <Box sx={{ backgroundColor: 'rgba(252, 186, 3,0.6)', p: 2, }}>

          <Typography sx={{ fontWeight: 'bold' }}>

            Notdienst:
          </Typography>
          <Typography>

            Werktags 19-7 Uhr und am Wochenende:
            Ärztlicher Notdienst Brackenheim,
            Maulbronner Straße 15,
            Tel. 07135-9360821 oder Tel. 116 117.
          </Typography>

          <Typography sx={{mt:3}}>

            In <b>dringenden Notfällen</b> wenden Sie sich bitte an die Rettungsleitstelle <b>Tel. 19222</b> oder <b>112</b>.
          </Typography>




        </Box>
        <Box sx={{ flex: 1 }}></Box>
      </Box>



    </>



  );
}

export default Notdienst;









