import React, { useEffect, useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Close, Router } from '@mui/icons-material';
import { Box, Icon, Typography, useTheme } from '@mui/material';
import LogoSVG from '../logoSVG';
import Menu from '@mui/icons-material/Menu';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';




function Footer() {
  // const [onlineStat,setOnlineStat] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
  useEffect(() => {
    setOpenMenu(true)
  }, [matchesLG])
  const matchesLG_Down = useMediaQuery(theme.breakpoints.up('xs'));
  useEffect(() => {
    if (!matchesLG) {

      setOpenMenu(false)
    }
  }, [matchesLG_Down, matchesLG])

  const [openMenu, setOpenMenu] = useState(false)

  return (
    <>
      <Box sx={{ height: 50, display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(234, 191, 64)', color: 'white', pl: 3 }}>
        <Box sx={{ flex: 1 }}></Box>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', color: '#505050', justifyContent: 'center' }}>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: 1 }}></Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>

              <Box onClick={() => { navigate("/Impressum") }} sx={{ ':hover': { fontWeight: 'bold', cursor: 'pointer' }, mr: 3 }}>Impressum</Box>
              <Box onClick={() => { navigate("/Datenschutz") }} sx={{ ':hover': { fontWeight: 'bold', cursor: 'pointer' }, mr: 3 }}>Datenschutz</Box>
            </Box>
            <Box sx={{ flex: 1 }}></Box>
          </Box>
          <Box >

            
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}></Box>

      </Box>
    </>



  );
}

export default Footer;









