import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';

function RezeptbestellungDatenschutz() {
  // const [onlineStat,setOnlineStat] = useState(false);




  return (
    <>
                  <b>Unsere allgemeine Informationen zum Datenschutz finden Sie <Link href="/Datenschutz">hier</Link>.</b>
            <br />
             Diese gelten auch für die Verwendung unseres Rezeptbestellungs- und Überweisungsformulars.
            <br />
            <br />
            <b>Für die Verwendung unseres Rezeptbestellungs- und Überweisungsformulars weisen wir Sie zusätzlich auf Folgendes hin:</b>
            <br />
            <br />
              Die Daten, die Sie uns über das Formular übermitteln, 
              werden verschlüsselt übertragen und nur für die Bearbeitung Ihrer Anfrage verwendet. 
              Wir geben Ihre Daten nicht an Dritte weiter. 
              Nach Abschluss der Bearbeitung Ihrer Anfrage werden Ihre Daten nach einem Monat aus dem Bestellsystem gelöscht. 
              <br />
              <br />
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
              <br />
              <br />
              <b>Bitte beachten Sie zudem den Punkt "Patienteninformation zum Datenschutz" in unserer Datenschutzerklärung (<Link href="/Datenschutz">hier</Link>)</b>
              <br />
              <br />
              Sie haben jederzeit das Recht, Auskunft über die bei uns gespeicherten Daten zu erhalten, 
              sowie das Recht auf Berichtigung, Löschung oder Sperrung Ihrer Daten. 
              Bitte wenden Sie sich dazu an unsere Praxis.
              <br />
              <br />
              <br />
              <b>Hosting der Schnittstelle zur Übermittlung der Daten:</b>
              <br />
              <br />
                Wir hosten die Anwendung bei folgendem Anbieter:
                <br />
                Strato
                <br />
                <br />
                Anbieter ist die Strato AG, Otto-Ostrowski-Straße 7, 10249 Berlin (nachfolgend „Strato“). Wenn Sie unser Formular verwenden, erfasst Strato verschiedene Logfiles inklusive Ihrer IP-Adressen.
                <br />
                <br />
                Weitere Informationen entnehmen Sie der Datenschutzerklärung von Strato: <Link href="https://www.strato.de/datenschutz/">https://www.strato.de/datenschutz/</Link>.
                <br />
                Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
                
                <br />
                <br />
                Auftragsverarbeitung
                <br />
                Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
               



    </>



  );
}

export default RezeptbestellungDatenschutz;









