import React, { useEffect, useMemo, useState } from "react";
// import logo from './logo.svg';
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CheckCircle, ClearAllOutlined, Router } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Backdrop,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import KurzUebersicht from "./KurzUebersicht";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RezeptbestellungDatenschutzDialog from "./RezeptbestellungDatenschutzDialog";

type IFinalMessage = {
  message: string;
  severity: "success" | "error";
};

function Rezeptbestellung() {
  // const [onlineStat,setOnlineStat] = useState(false);
  const [element, setElement] = React.useState("rezept");
  const [agreeAGB, setAgreeAGB] = React.useState(false);
  const [rezeptwunsch, setRezeptWunsch] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [birthdate, setBirthdate] = React.useState<Dayjs | null>(dayjs());
  const [rezept_url, setRezeptUrl] = React.useState("");
  const [showFinalMessage, setShowFinalMessage] = React.useState<
    IFinalMessage | undefined
  >(undefined);

  const options = { method: "GET" };
  
  const getFormId = async () => {
    return await fetch(process.env.NODE_ENV === "production"?"https://rezepte.praxis-staehle-winkler.de/requestFormId":"http://localhost:13000/requestFormId", options)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setShowFinalMessage({
            message:
              "Fehler beim Senden des Formulars. Bitte versuchen Sie es später erneut.",
            severity: "error",
          });
        }
      })
      .then((response) => {
        if (response.formID) {
          return response.formID;
        } else {
          return null;
        }
      })
      .catch((err) => console.error(err));
  };
  const handleCloseBackdrop = () => {
    setAgreeAGB(false);
    setRezeptWunsch("");
    setName("");
    setEmail("");
    setBirthdate(null);
    setShowFinalMessage(undefined);
  };

  const sendForm = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    let formID = await getFormId();
    if (formID === null) {
      setShowFinalMessage({
        message:
          "Fehler beim Senden des Formulars. Bitte versuchen Sie es später erneut.",
        severity: "error",
      });
    } else {
      console.log("sendForm");
      if (
        !(
          !agreeAGB ||
          email === "" ||
          name === "" ||
          rezeptwunsch === "" ||
          birthdate === null ||
          !validMail ||
          !validName
        )
      ) {
        const birthdateToString = (birthdate: any) =>
          String(birthdate["$D"]).padStart(2, "0") +
          "." +
          String(birthdate["$M"] + 1).padStart(2, "0") +
          "." +
          String(birthdate["$y"]).padStart(2, "0");
      

        let data = {
          name: name.trim(),
          email: email.trim(),
          rezepte: rezeptwunsch.trim(),
          datenschutz: agreeAGB,
          birthdate: birthdateToString(birthdate),
          requestFormId: formID,
          rezept_or_ueberweisung: element,
        };

        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };

        fetch(process.env.NODE_ENV === "production"?"https://rezepte.praxis-staehle-winkler.de/medicals":"http://localhost:13000/medicals", options)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              setShowFinalMessage({
                message:
                  "Fehler beim Senden des Formulars. Bitte versuchen Sie es später erneut.",
                severity: "error",
              });
            }
          })
          .then((response) => {
            console.log(response);
            setShowFinalMessage({
              message:
                "Vielen Dank für Ihre Anfrage! Wir kontaktieren Sie, sobald es Neuigkeiten zu Ihrer Anfrage gibt.",
              severity: "success",
            });
          })
          .catch((err) => console.error(err));
      }
    }
    // etc...
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      setRezeptUrl("https://rezepte.praxis-staehle-winkler.de");
    } else {
      setRezeptUrl("http://localhost:13000");
    }
  }, []);

  const handleChangeRezeptWunsch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRezeptWunsch(event.target.value);
  };
  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeAGB(event.target.checked);
  };
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newElement: string
  ) => {
    if (element !== newElement && newElement !== null) {
      setAgreeAGB(false);
      setElement(newElement);
      setRezeptWunsch("");
    }
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleChangeBirthdate = (newValue: Dayjs | null) => {
    setBirthdate(newValue);
  };
  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const validateName_forSpaces = (name: string) => {
    // validate if name contains at least one space

    return (
      /^[a-zA-ZäöüÄÖÜß-\s]*$/.test(name) &&
      name.split(" ").reduce((p, c) => (p !== 0 && c.length > 0 ? 1 : 0), 1) &&
      name.split(" ").length > 1
    );
  };
  const validName = useMemo(() => {
    return validateName_forSpaces(name.trim());
  }, [name]);
  const validMail = useMemo(() => {
    return validateEmail(email.trim());
  }, [email]);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            p: 4,
            backgroundColor: "rgba(255,255,255,1)",
          }}
        >
          <Box sx={{ flex: 3, p: 2 }}>
            <form autoComplete="on" onSubmit={sendForm}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                Rezepte und Überweisungen bestellen
              </Typography>
              <br />

              <ToggleButtonGroup
                // color="primary"
                sx={{
                  "& .MuiToggleButtonGroup-grouped.Mui-selected": {
                    color: "black",
                    backgroundColor: "#eabf40",
                  },
                }}
                value={element}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="rezept">Rezept</ToggleButton>
                <ToggleButton value="ueberweisung">Überweisung</ToggleButton>
              </ToggleButtonGroup>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mt: 2, mb: 1 }}
              >
                {element === "rezept" ? "Rezept" : "Überweisung"}
                {/* Rezeptbestellung und Überweisungen */}
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: "bold", mt: 2, mb: 1, color: "red" }}
              >
                {
                  "Bitte beachten Sie: Um diesen Dienst nutzen zu können, müssen Sie bei uns als Patient bereits bekannt sein. "+(element === "rezept" ?"Außerdem muss uns Ihre Krankenversicherungskarte bereits vorliegen.":"")
                }
                {/* Rezeptbestellung und Überweisungen */}
              </Typography>
              <FormControl sx={{ mt: 2 }}>
                {/* {
            "name":"Tobias",
            "email":"tobi.staehle@web.de",
            "rezepte":"Ibuprofen900",
            "datenschutz":true,
            "birthdate":"30.04.2016",
           } */}
                <TextField
                  id="datemailaksljf"
                  label="E-Mail-Adresse"
                  defaultValue=""
                  helperText={
                    "Wir benötigen Ihre E-Mail-Adresse, um Sie bei Fertigstellung " +
                    (element === "rezept" ? "des Rezeptes" : "") +
                    (element === "ueberweisung" ? "der Überweisung" : "") +
                    " zu kontaktieren."
                  }
                  value={email}
                  variant="outlined"
                  sx={{ mb: 2 }}
                  inputProps={{ maxLength: 250 }}
                  required
                  onChange={handleEmailChange}
                />
                <TextField
                  id="datnameaksljf"
                  label="Vor- und Nachname"
                  defaultValue=""
                  helperText={"Wir benötigen Ihren Namen, um "+(element==="rezept"?"das Rezept":"die Überweisung")+" auf Sie ausstellen zu können und Sie mit unserer Patientenakte zu validieren."}
                  variant="outlined"
                  required
                  inputProps={{ maxLength: 250 }}
                  sx={{ mb: 2 }}
                  onChange={handleNameChange}
                  value={name}
                />

                <DatePicker
                  //defaultValue={dayjs("2022-04-17")}
                  sx={{ mb: 2 }}
                  value={birthdate}
                  label="Geburtsdatum"
                  format="DD.MM.YYYY"
                  slotProps={{
                    textField: {
                      helperText:
                        "Wir benötigen Ihr Geburtsdatum, um Sie mit unserer Patientenakte zu validieren.",
                      required: true,
                    },
                  }}
                  onChange={handleChangeBirthdate}
                />
                {element === "rezept" && (
                  <TextField
                    id="rezeptlakslif"
                    label="Rezeptwunsch"
                    placeholder="Bsp. Ibuprofen 600mg, 20 Tabletten"
                    multiline
                    variant="outlined"
                    sx={{ mb: 2 }}
                    required
                    inputProps={{ maxLength: 1000 }}
                    helperText={
                      String(rezeptwunsch.length) +
                      "/1000 Zeichen. " +
                      "Die Rezeptbestellung ist nur für Medikamente möglich, die uns bereits bekannt sind und regelmäßig von uns verschrieben werden."
                    }
                    value={rezeptwunsch}
                    onChange={handleChangeRezeptWunsch}
                  />
                )}
                {element === "ueberweisung" && (
                  <TextField
                    id="rezeptlakslif"
                    label="Benötigte Überweisung"
                    placeholder="Facharzt XY (Adresse etc.) MRT, etc."
                    multiline
                    variant="outlined"
                    sx={{ mb: 2 }}
                    required
                    inputProps={{ maxLength: 1000 }}
                    helperText={
                      String(rezeptwunsch.length) +
                      "/1000 Zeichen. " +
                      "Bitte nennen Sie uns die Fachrichtung des/der Ärzt*in und den Grund für die Überweisung."
                    }
                    value={rezeptwunsch}
                    onChange={handleChangeRezeptWunsch}
                  />
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeCheck}
                      name="Datenschutz"
                      required
                      checked={agreeAGB}
                    />
                  }
                  label={<>
                    {"* Ich akzeptiere die "}
                    <RezeptbestellungDatenschutzDialog ></RezeptbestellungDatenschutzDialog>
                      {". Ich stimme der Verarbeitung meiner Daten zur Erstellung " +
                    (element === "rezept" ? "des Rezeptes" : "") +
                    (element === "ueberweisung" ? "der Überweisung" : "") +
                    " zu. Bei Fragen können Sie sich gerne an uns wenden."
                    }
                  </>
                  }
                  sx={{ mb: 2 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 2,
                    backgroundColor: "#eabf40",
                    color: "#505050",
                    ":hover": { backgroundColor: "#eabf40" },
                  }}
                  disabled={
                    !agreeAGB ||
                    email === "" ||
                    name === "" ||
                    rezeptwunsch === "" ||
                    birthdate === null ||
                    !validMail ||
                    !validName
                  }
                >
                  Abschicken
                </Button>
              </FormControl>
            </form>

            {showFinalMessage !== undefined &&
              showFinalMessage.message !== undefined && (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={true}
                  onClick={() => {
                    handleCloseBackdrop();
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      width: "80%",
                      p: 2,
                      maxWidth: "sm",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ flex: 1 }} />
                    <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
                      <Box sx={{ flex: 1 }} />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ flex: 1 }}></Box>
                        {showFinalMessage.severity === "error" && (
                          <ClearAllOutlined
                            sx={{ color: "red", fontSize: 40 }}
                          />
                        )}
                        {showFinalMessage.severity === "success" && (
                          <CheckCircleOutlineIcon
                            sx={{ color: "green", fontSize: 40 }}
                          />
                        )}
                        <Box sx={{ flex: 1 }}></Box>
                      </Box>
                      <Typography variant="h6" sx={{ ml: 1 }}>
                        {showFinalMessage.message}
                      </Typography>

                      <Box sx={{ flex: 1 }} />
                    </Box>
                    {showFinalMessage.severity === "error" && (
                      <Button
                        onClick={() => {
                          handleCloseBackdrop();
                        }}
                        variant="outlined"
                      >
                        Erneut probieren
                      </Button>
                    )}
                    {showFinalMessage.severity === "success" && (
                      <Button
                        onClick={() => {
                          handleCloseBackdrop();
                        }}
                        variant="outlined"
                      >
                        Schließen
                      </Button>
                    )}
                    <Box sx={{ flex: 1 }} />
                  </Paper>
                </Backdrop>
              )}
          </Box>
          <KurzUebersicht></KurzUebersicht>
        </Box>

        <Box
          sx={{ minHeight: "50px", backgroundColor: "rgba(255,255,255,1)" }}
        ></Box>
      </LocalizationProvider>
      
    </>
  );
}

export default Rezeptbestellung;
