import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

function KurzUebersicht() {
  // const [onlineStat,setOnlineStat] = useState(false);




  return (
    <>
      <Box sx={{ flex: 1, flexDirection: 'column', display: { xs: 'none', md: 'flex' }, color: '#505050' }}>
        <Box sx={{ backgroundColor: 'rgba(252, 186, 3,0.6)', p: 2, }}>
          <Typography sx={{ m: 0, p: 0, fontWeight: 'bold' }}>Hausarztpraxis</Typography>
          <Typography sx={{ m: 0, p: 0, fontWeight: 'bold' }}>Dr. med. S. Stähle &</Typography>
          <Typography sx={{ m: 0, p: 0, fontWeight: 'bold' }}>Dr. med. S. Winkler</Typography>
          <Typography>Kelterweg 7</Typography>
          <Typography>74081 Heilbronn-Horkheim</Typography>
          <Typography onClick={() => window.open("tel:+497131574691")} sx={{ ":hover": { textDecoration: 'underline', cursor: 'pointer' }, mt: 2, p: 0 }}>Tel: 07131-574691</Typography>
          <Typography sx={{ mb: 2, p: 0 }}>Fax: 250916</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>

            Sprechzeiten:
          </Typography>
          <Typography>

            Vormittags:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: 1 }}>
              <Typography>Mo - Do</Typography>
              <Typography>Fr</Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography>8-12 Uhr</Typography>
              <Typography>8-13 Uhr</Typography>
            </Box>

          </Box>
          <Typography>

            Nachmittags:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: 1 }}>
              <Typography>Mo & Mi</Typography>
              <Typography>Do</Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography>15-17 Uhr</Typography>
              <Typography>16-18 Uhr</Typography>
            </Box>


          </Box>
          <Typography> und nach Vereinbarung.</Typography>
          <Typography> Bitte vereinbaren Sie Ihre Termine telefonisch.</Typography>
          <Typography> </Typography>
        </Box>
        <Box sx={{ flex: 1 }}></Box>
      </Box>



    </>



  );
}

export default KurzUebersicht;









