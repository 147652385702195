import { Link, Routes, Route, useLocation, Navigate, useNavigate, Outlet, BrowserRouter, useRoutes, RouteObject, Router } from "react-router-dom";
import { routesOfApp } from "./AppRoutes";
const AppRoutes = ()=>{
   
  const routes = useRoutes(routesOfApp);
  return routes;
}

const AppRouter = () => {
  //return routes;

  return (
    <>
    {/* <BrowserRouter> */}

    <AppRoutes></AppRoutes>
    {/* </BrowserRouter> */}
      {/* <Routes >
        <Route
        element={
          
          <Outlet />
          
        }
        >
        
        <Route path="/" element={<StartPage />} />
        </Route>
        <Route
        element={
          <RequireAuth>
          <Outlet />
          </RequireAuth>
        }
        >
        
        <Route path="/LoggedIn" element={<StartPage />} />
        </Route>
      </Routes> */}
      </>
  )
}
export default AppRouter;