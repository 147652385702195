import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import KurzUebersicht from './KurzUebersicht';

function Impressum() {
	// const [onlineStat,setOnlineStat] = useState(false);


	let email="info@"
	 email +="praxis-staehle-winkler"
	 email +=".de";


	return (
		<>
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', p: 4, backgroundColor: 'rgba(255,255,255,1)' }}>
				<Box sx={{ flex: 3, p: 3 }}>
					<Typography variant='h5'>Impressum</Typography>
					<p >Dr. med. Sabine Stähle und Dr. med. Sabine Winkler
						<br /> Kelterweg 7<br />
						74081 Heilbronn-Horkheim <br />
						Rechtsform: GbR
					</p>
					<p >Telefon <a href="tel:+497131574691"> 07131 574691</a><br />
						Fax 07131 250916<br />
						<a href={'mailto:'+email}>{email}</a>
						<br/>
						(Die E-Mail Adresse dient nicht zur Terminvereinbarung oder für Notfälle und wird auch nicht regelmäßig abgerufen.)
						</p>

					{/* <br /> */}
					<p >Berufsbezeichnung:<br />
						Fachärztinnen für Allgemeinmedizin, die Berufsbezeichnung Arzt wurde in Deutschland verliehen.</p>
					<p >Zuständige Aufsichtbehörde: Landesärztekammer Baden-Württemberg <a href="https://www.aerztekammer-bw.de/">www.aerztekammer-bw.de</a></p>
					<p >Berufsordnung für Ärzte abzurufen unter <a href="https://www.aerztekammer-bw.de/">www.aerztekammer-bw.de</a></p>
					<p >Zuständige Kassenärztliche Vereinigung: Baden-Württemberg <a href="https://www.kvbawue.de/">www.kvbawue.de</a><br /></p>
					<p >Datenschutzerklärung: "Wir erheben und nutzen personenbezogene Daten, wie Name, Postanschrift, E-Mail-Adresse, Telefonnummer oder das Geburtsdatum ausschließlich in Übereinstimmung mit dem jeweils geltenden Datenschutzrecht. Die Daten werden nur gespeichert, wenn sie aktiv übermittelt werden. Die Daten werden nur zur Beantwortung von Anfragen oder zur Zusendung von Informationsmaterial verwendet. Kontaktdaten, die im Rahmen von Anfragen angegeben werden, werden ausschließlich für die Korrespondenz verwendet."<br />Weitere Informationen finden Sie <a href="datenschutz.html">hier</a>.</p>
					<p >"Es wird keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen übernommen. Haftungsansprüche, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt."</p>
					<p >„Hiermit distanzieren wir uns ausdrücklich von den Inhalten der auf unserer Internetseite gelinkten Seiten; diese Erklärung gilt für alle auf unserer Internetpräsenz angebrachten Links.“</p>


				</Box>
				<KurzUebersicht></KurzUebersicht>
			</Box>
			<Box sx={{ p: 1, backgroundColor: 'rgba(255,255,255,1)' }}>

				<Box sx={{ minHeight: '50px' }}>

				</Box>
			</Box>
		</>



	);
}

export default Impressum;









