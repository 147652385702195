import React, { useState } from 'react';
// import logo from './logo.svg';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Router } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import KurzUebersicht from './KurzUebersicht';

function AboutUs() {
  // const [onlineStat,setOnlineStat] = useState(false);




  return (
    <>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', p: 4, backgroundColor: 'rgba(255,255,255,1)' }}>
        <Box sx={{ flex: 3, p:2 }}>
          <Typography variant='h5' sx={{fontWeight:'bold',mb:2,}}>Über Uns</Typography>
          <br/>
          Unsere Gemeinschaftspraxis besteht seit 2011. Im Jahr 2021 sind wir in unsere neuen, ebenerdigen und barrierefreien Räume im Kelterweg 7 in Heilbronn-Horkheim umgezogen.
          
          <Typography sx={{mt:5}} variant='h5'>Dr. med. Sabine Stähle</Typography>
          <Typography sx={{mb:1}} >Geb. 1966 in Heilbronn</Typography>

          <Typography >Studium in Heidelberg, mehrere Auslandsaufenthalte.
          Klinische Ausbildung im Krankenhaus Heilbronn und in Rehakliniken.
          Seit vielen Jahren Allgemeinärztin in Heilbronn.</Typography>

          <Typography sx={{mt:5}} variant='h5'>Dr. med. Sabine Winkler</Typography>
          <Typography sx={{mb:1}} >Geb. 1966 in Buchen</Typography>

          <Typography >Studium in Heidelberg und Mannheim, mehrere Auslandsaufenthalte.
          Klinische Ausbildung im Krankenhaus „Am Plattenwald“ in Bad Friedrichshall.
          Seit 2003 hausärztlich in der Region Heilbronn tätig.</Typography>

        </Box>
      <KurzUebersicht></KurzUebersicht>
      </Box>
      
        <Box sx={{ minHeight: '50px',backgroundColor: 'rgba(255,255,255,1)'  }}>

        </Box>
    </>



  );
}

export default AboutUs;









